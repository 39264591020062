import type { FunctionComponent } from 'react'
import React from 'react'
import { Button, TypographyV2 as Typography } from '@which/seatbelt'

import styles from './PIApplyCancelPanel.module.scss'

export const PIApplyCancelPanel: FunctionComponent<Props> = ({ resultsCount, onClick }) => {
  return (
    <div className={styles.panel} data-testid="ea-apply-cancel-panel">
      <Typography textStyle="sb-text-body-default-strong" className={styles.panelResults}>
        {resultsCount} Results
      </Typography>
      <div className={styles.panelButtonWrapper}>
        <Button
          className={styles.panelButton}
          appearance="secondary"
          onClick={() => onClick()}
          data-testid="ea-filter-cancel-button"
        >
          <Typography tag="span" textStyle="sb-text-body-default-strong">
            Cancel
          </Typography>
        </Button>
        <Button
          className={styles.panelButton}
          onClick={() => onClick()}
          data-testid="ea-filter-apply-button"
          aria-controls="ea-search-results"
        >
          <Typography tag="span" textStyle="sb-text-body-default-strong">
            Apply
          </Typography>
        </Button>
      </div>
    </div>
  )
}

type Props = {
  resultsCount: number
  onClick: () => void
}
