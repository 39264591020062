import { getFilterLevels } from './getFilterLevels'
import { removeDuplicateArticles } from './removeDuplicateArticles'

export const applyFilters = (primaryArticleData, filters) => {
  const { secondaryFilters, tertiaryFilters } = getFilterLevels()

  let secondaryFilterItems = []
  secondaryFilterItems = filters.filter((filter) => {
    if (filter.value) {
      return secondaryFilters.includes(filter.key)
    }
  })

  const tertiaryFilterItems = filters.filter((filter) => {
    if (filter.value) {
      return tertiaryFilters.includes(filter.key)
    }
  })

  let secondaryFilteredArticles = primaryArticleData
  if (secondaryFilterItems.length > 0) {
    secondaryFilteredArticles = []
    secondaryFilterItems.forEach(({ key, value }: Filters) => {
      switch (key) {
        case 'filters.contentTypes':
          const contentTypesArray = value.split(',').filter((n) => n)
          if (contentTypesArray && contentTypesArray.length > 0 && primaryArticleData) {
            contentTypesArray.forEach((contentType) => {
              const contentTypeFilteredData = primaryArticleData.filter(
                (article) =>
                  article.topicHeading.text.replace(/\s/g, '-').toLowerCase() ===
                  contentType.toLowerCase()
              )
              contentTypeFilteredData.map((article) => secondaryFilteredArticles.push(article))
            })
          }
          return
        case 'filters.topics':
          const appliedTopicFilters = value.split(',').filter((n) => n)
          if (appliedTopicFilters && appliedTopicFilters.length > 0 && primaryArticleData) {
            appliedTopicFilters.forEach((topic) => {
              const topicsFilteredData = primaryArticleData.filter((article) => {
                const topics = article.trendingTopics.replace(/\s+/g, '-').toLowerCase()
                const topicsArray = topics.split(',')
                return topicsArray.includes(topic.replace(/\s+/g, '-').toLowerCase())
              })
              topicsFilteredData.map((article) => secondaryFilteredArticles.push(article))
            })
          }
          return
      }
    })
  }

  let tertiaryFilteredArticles = secondaryFilteredArticles
  if (tertiaryFilterItems.length > 0) {
    tertiaryFilteredArticles = []
    tertiaryFilterItems.forEach(({ key, value }: Filters) => {
      switch (key) {
        case 'filters.year':
          const yearArray = value
            .toString()
            .split(',')
            .filter((n) => n)
          if (yearArray && yearArray.length > 0 && tertiaryFilteredArticles) {
            yearArray.forEach((year) => {
              const yearFilteredData = secondaryFilteredArticles?.filter((article) => {
                const articleYear = new Date(article.date).getFullYear().toString()
                return articleYear.includes(year)
              })
              yearFilteredData?.map((article) => tertiaryFilteredArticles.push(article))
            })
          }
          return
        case 'filters.author':
          const authorsFilterArray =
            value.indexOf(',') === -1 ? [value] : value.split(',').filter((n) => n)
          if (authorsFilterArray && authorsFilterArray.length > 0 && tertiaryFilteredArticles) {
            authorsFilterArray.forEach((authorFilter) => {
              const authorsFilteredData = secondaryFilteredArticles?.filter((article) => {
                const articleAuthorsArray: string[] = []

                article.authors.map((articleAuthor) => {
                  articleAuthorsArray.push(articleAuthor.replace(/\s+/g, '-').toLowerCase())
                })

                let newAuthorValue = authorFilter.replace(/\s+/g, '-').toLowerCase()
                if (newAuthorValue.includes('which')) {
                  newAuthorValue = newAuthorValue.replace(/(which)/i, 'which?')
                }

                return articleAuthorsArray.includes(newAuthorValue)
              })
              authorsFilteredData?.map((article) => tertiaryFilteredArticles.push(article))
            })
          }
          return
        case 'filters.category':
          if (value) {
            const categoryFilteredData = secondaryFilteredArticles?.filter(
              (article) => article.category.toLowerCase() === value.toLowerCase()
            )
            categoryFilteredData?.map((article) => tertiaryFilteredArticles.push(article))
          }
          return
      }
    })
  }

  const filteredArticles = removeDuplicateArticles(tertiaryFilteredArticles)

  return filteredArticles
}

type Filters = {
  key: string
  value: string
}
