export const UpdateFilterState = (data: updateFilterStateProps) => {
  const { filterState, type, key, value } = data
  const obj = filterState
  obj.tertiaryFilters.page = 1

  switch (type) {
    case 'primaryFilters':
      obj.primaryFilters.searchTerm.value = value
      break
    case 'secondaryFilters':
      if (key === 'contentTypes') {
        obj.secondaryFilters.contentTypes = value
      }
      if (key === 'topics') {
        obj.secondaryFilters.topics = value
      }
      break
    case 'tertiaryFilters':
      if (key === 'author') {
        obj.tertiaryFilters.author = value
      }
      if (key === 'category') {
        obj.tertiaryFilters.category = value
      }
      if (key === 'year') {
        obj.tertiaryFilters.year = value
      }
      if (key === 'page') {
        obj.tertiaryFilters.page = Number(value)
      }
      break
    default:
      return obj
  }
  return obj
}

type updateFilterStateProps = {
  filterState: any
  type: string
  key: string
  value: any
}
