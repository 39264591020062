import type { PolicyAndInsightSearch } from '../../../generated/frontend'

//#region Types
type FilterState = {
  primaryFilters: {
    searchTerm: {
      value: string
    }
  }
  secondaryFilters: {
    contentTypes: string[]
    topics: string[]
  }
  tertiaryFilters: {
    year: string[]
    author: string[]
    category: string
    page: number
  }
  dataSet: any
}
type FilterType = 'searchTerm' | 'category' | 'authors' | 'contentTypes' | 'topics' | 'year'
type FilterArticleResultsProps = {
  articleData: PolicyAndInsightSearch[] | undefined
  queryStrArray: {
    key: string
    value: string
  }[]
  setFilters: any
  filtersState: FilterState
}
//#endregion Types

export const FilterArticleResults = ({
  articleData,
  queryStrArray,
  setFilters,
  filtersState,
}: FilterArticleResultsProps) => {
  const filterCurrentData = (
    data: PolicyAndInsightSearch[] | undefined,
    filter: FilterType,
    value: string
  ) => {
    const lowerCaseValue = value.toLowerCase()
    const filterArticleData = data?.filter(function (article) {
      switch (filter) {
        case 'searchTerm':
          return (
            article.title.toLowerCase().includes(lowerCaseValue) ||
            article.imageAlt.toLowerCase().includes(lowerCaseValue) ||
            article.standFirst.toLowerCase().includes(lowerCaseValue)
          )
        case 'category':
          return article.category.toLowerCase() === lowerCaseValue
        case 'authors':
          const articleAuthors = article.authors?.map((author) => author.toLowerCase())
          let authorsFilterStr = lowerCaseValue

          if (authorsFilterStr.includes('which')) {
            authorsFilterStr = authorsFilterStr.replace(/(which)/i, 'which?')
          }

          const authorsFilterList: string[] = authorsFilterStr.split(',')

          return authorsFilterList.every((author) => articleAuthors.includes(author))
        case 'contentTypes':
          const articleType = article.topicHeading.text.replace(/\s/g, '-').toLowerCase()
          return value.includes(articleType)
        case 'topics':
          const topics = article.trendingTopics.replace(/\s+/g, '-').toLowerCase()
          return value
            .split(',')
            .every((i) => topics.includes(i.replace(/\s+/g, '-').toLowerCase()))
        case 'year':
          const articleYear = new Date(article.date).getFullYear().toString()
          return value.includes(articleYear)
      }
    })
    return filterArticleData
  }

  let filterData = articleData
  const filters = filtersState

  queryStrArray.forEach((param) => {
    if (param.key === 'filters.searchTerm') {
      filters.primaryFilters.searchTerm.value = param.value
      filterData = filterCurrentData(filterData, 'searchTerm', param.value)
    } else if (param.key === 'filters.category') {
      filters.tertiaryFilters.category = param.value
      filterData = filterCurrentData(filterData, 'category', param.value)
    } else if (param.key === 'filters.contentTypes') {
      const articleTypeValues = param.value.split(',')
      articleTypeValues.forEach((element) => {
        if (
          filters.secondaryFilters.contentTypes &&
          !filters.secondaryFilters.contentTypes.includes(element)
        ) {
          filters.secondaryFilters.contentTypes.push(element)
        }
      })
      filterData = filterCurrentData(filterData, 'contentTypes', param.value)
    } else if (param.key === 'filters.author') {
      const authorValues = param.value.split(',')
      authorValues.forEach((element) => {
        if (filters.tertiaryFilters.author && !filters.tertiaryFilters.author.includes(element)) {
          let newElement = element
          if (newElement.includes('Which')) {
            newElement = newElement.replace(/(Which)/i, 'Which?')
          }
          if (filters.tertiaryFilters.author.indexOf(newElement) === -1) {
            filters.tertiaryFilters.author.push(newElement)
          }
        }
      })
      filterData = filterCurrentData(filterData, 'authors', param.value)
    } else if (param.key === 'filters.topics') {
      const topicValues = param.value.split(',')
      topicValues.forEach((element) => {
        if (!filters.secondaryFilters.topics.includes(element)) {
          filters.secondaryFilters.topics.push(element)
        }
      })
      filterData = filterCurrentData(filterData, 'topics', param.value)
    } else if (param.key === 'filters.year') {
      const yearValues = param.value.split(',')
      yearValues.forEach((year: string) => {
        if (filters.tertiaryFilters.year && !filters.tertiaryFilters.year.includes(year)) {
          filters.tertiaryFilters.year.push(year)
        }
      })
      filterData = filterCurrentData(filterData, 'year', param.value)
    }
  })

  setFilters(filters)

  return filterData
}
