import type { FunctionComponent } from 'react'
import React from 'react'
import { LinkButton, TagClickable, TypographyV2 as Typography } from '@which/seatbelt'

import styles from './PIAppliedFilters.module.scss'

export const PIAppliedFilters: FunctionComponent<Props> = ({
  filters,
  handleFilterClick,
  handleClearAllFilters,
}) => {
  if (!filters || filters.length === 0) {
    return null
  }

  return (
    <div className={styles.appliedFilters} data-testid="applied-filters">
      <div className={styles.flexWrapper}>
        <Typography textStyle="sb-text-body-default-strong" tag="span">
          Applied filters
        </Typography>
        <LinkButton
          className={styles.clearAllFilters}
          data-testid="clear-filters"
          onClick={handleClearAllFilters}
          aria-controls="ea-search-results"
        >
          <Typography tag="span" textStyle="sb-text-interface-body-small-regular">
            Clear all filters
          </Typography>
        </LinkButton>
      </div>
      <ul className={styles.tagWrapper}>
        {filters.map((filter, index) => (
          <li className={styles.tagWrapperFilterTag} key={`${filter.text} ${index}`}>
            <TagClickable
              text={filter.text}
              withIcon
              clickHandler={() => handleFilterClick(filter)}
              id={`${filter.id}-${filter.text}`}
              aria-label={`Remove ${filter.text} filter`}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

type Props = {
  filters?: {
    id: string
    text: string
    type: string
  }[]
  handleFilterClick: any
  handleClearAllFilters: any
}
