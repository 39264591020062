import type { FunctionComponent } from 'react'
import React from 'react'
import { Button, TypographyV2 as Typography } from '@which/seatbelt'
import { FilterFunnelIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import styles from './PIFilterButton.module.scss'

export const PIFilterButton: FunctionComponent<FilterButtonProps> = ({
  filterCount = 0,
  onClick,
  ...rest
}) => {
  return (
    <Button
      className={styles.filterButtonInSortby}
      onClick={() => onClick()}
      data-testid="ea-filter-button"
      aria-controls="ea-search-results"
      {...rest}
    >
      {filterCount > 0 ? (
        <Typography
          tag="span"
          textStyle="sb-text-body-small-regular"
          className={styles.filterCount}
        >
          {filterCount}
        </Typography>
      ) : (
        <FilterFunnelIcon className={styles.filterFunnel} />
      )}
      Filters
    </Button>
  )
}

///////// IMPLEMENTATION /////////

type FilterButtonProps = {
  filterCount?: number
  onClick: () => void
}
