export const CheckboxFilterData = (
  articles: any[],
  filterState: string[],
  filterType: 'contentTypes' | 'year'
) => {
  const checkboxData: { label: string; count: number; value: string; checked: boolean }[] = []

  const isCheckboxChecked = (value: string) =>
    filterState &&
    filterState.some((stateValue: string) => stateValue === value.replace('year-', ''))

  if (articles) {
    articles.forEach((article: any) => {
      const label =
        filterType === 'year'
          ? new Date(article.date).getFullYear().toString()
          : article.topicHeading.text

      const formattedValue =
        filterType === 'year' ? `year-${label}` : label.replace(/\s/g, '-').toLowerCase()

      // checks if the filter is already in the array, if it is increase count by 1
      const isIncluded = checkboxData.some((filter: { value: string; count: number }) => {
        const filterTypeInCheckbox = filter.value.includes(formattedValue)
        if (filterTypeInCheckbox) {
          filter.count += 1
        }
        return filterTypeInCheckbox
      })

      // If the filter is not in the array add it in and check against state to see if the box is checked.
      if (!isIncluded) {
        checkboxData.push({
          label,
          count: 1,
          value: formattedValue,
          checked: isCheckboxChecked(formattedValue),
        })
      }
    })
  }

  return checkboxData
}
