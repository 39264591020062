import type { DataLayerInfo, PageInfo } from '../../shared/types'

export const getPolicyAndInsightSearchDataLayer = (pageInfo: PageInfo) => {
  const policyAndInsightSearchDataLayer: DataLayerInfo = {
    ...pageInfo,
    vertical: 'policy-and-insight',
    content_type: 'search',
  }

  delete policyAndInsightSearchDataLayer.sub_vertical

  return policyAndInsightSearchDataLayer
}
