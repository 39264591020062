export const getArticleCategories = (articles) => {
  const articleCategoriesData = [{ label: 'All subject areas', value: '' }]

  articles?.map((article: { category: string }) => {
    const { category } = article

    if (category !== '') {
      const categoriesDataLength = articleCategoriesData.filter(
        (articleCategory) => articleCategory.label === category
      )

      if (categoriesDataLength.length === 0) {
        articleCategoriesData.push({
          label: category,
          value: category,
        })
      }
    }
  })

  return articleCategoriesData
}
