export const SortArticlesResults = (articleData: any, sortBy: string) => {
  let sortedArray: any

  switch (sortBy) {
    case 'asc':
      sortedArray = articleData?.slice().sort((a, b) => +new Date(b.date) - +new Date(a.date))
      return { data: sortedArray, sortOptionSelected: 0 }
    case 'desc':
      sortedArray = articleData?.slice().sort((a, b) => +new Date(a.date) - +new Date(b.date))
      return { data: sortedArray, sortOptionSelected: 1 }
    default:
      return { data: articleData, sortOptionSelected: 0 }
  }
}
