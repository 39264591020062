import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './PIResultCount.module.scss'

export const PIResultCount: FunctionComponent<Props> = ({
  className,
  searchTerm,
  start,
  end,
  total,
}) => {
  return (
    <Typography
      tag="p"
      dataTestId="result-count"
      className={classnames(className, styles.count)}
      textStyle="sb-text-body-default-regular"
    >
      {total > 0 ? (
        <>
          <span data-testid="start-count" className={styles.emphasis}>
            {start}
          </span>{' '}
          to{' '}
          <span data-testid="end-count" className={styles.emphasis}>
            {end}
          </span>{' '}
          of{' '}
          <span data-testid="total-count" className={styles.emphasis}>
            {total}
          </span>{' '}
          results {searchTerm && `for '${searchTerm}'`}
        </>
      ) : (
        'No results available'
      )}
    </Typography>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className?: string
  searchTerm: string
  start: number
  end: number
  total: number
}
