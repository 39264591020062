export const BuildTopics = (filteredArticles: any, filtersState: any, handleChange: any) => {
  const topics: {
    label: string
    count: string
    value: string
    selected: boolean
    onChangeCallback: any
  }[] = []

  if (filteredArticles) {
    let allTopics: string[] = filteredArticles.map((a) => a.trendingTopics)
    allTopics = allTopics.filter(function (e) {
      return e.replace(/(\r\n|\n|\r)/gm, '')
    })

    allTopics = allTopics.map((a) => a.split(',')).flat()
    const uniqueTopics: string[] = Array.from(new Set(allTopics))

    uniqueTopics.forEach((topic) => {
      const lowerCaseTopic = topic.toLowerCase()
      const topicsArr = allTopics.filter((x) => x.toLowerCase() === lowerCaseTopic)

      if (!topics.some((x) => x.label.toLowerCase() === lowerCaseTopic)) {
        topics.push({
          label: topic.charAt(0).toUpperCase() + topic.slice(1),
          count: topicsArr.length.toString(),
          value: `${lowerCaseTopic}-topicselector`,
          selected: isCheckboxChecked(`${lowerCaseTopic.replace(/\s+/g, '-')}`, filtersState),
          onChangeCallback: handleChange,
        })
      }
    })
  }

  return topics.sort((a, b) => a.label.localeCompare(b.label))
}

const isCheckboxChecked = (value: string, filtersState: any) =>
  filtersState.secondaryFilters.topics &&
  filtersState.secondaryFilters.topics.some((articleType: string) => articleType === value)
