export const BuildHeadingText = (filtersState: buildHeadingTextProps) => {
  const headingText: string[] = []
  const searchValue: string = filtersState.primaryFilters.searchTerm.value
  const allArticleTypes = filtersState.secondaryFilters.contentTypes
  const allTopics = filtersState.secondaryFilters.topics
  const allTertiaryFilters = filtersState.tertiaryFilters

  let headingArticleText: string
  let headingTopicText: string
  let filtersText: string

  // Search term
  if (searchValue !== '') {
    headingText.push(`${searchValue}. `)
  }

  // Article types
  if (allArticleTypes.length > 0) {
    headingArticleText = `${
      allArticleTypes.length > 1 ? 'Types -' : 'Type -'
    } ${allArticleTypes.join(', ')}. `
    headingText.push(headingArticleText)
  }

  // Topics
  if (allTopics.length > 0) {
    headingTopicText = `${allTopics.length > 1 ? 'Topics -' : 'Topic -'} ${allTopics.join(', ')}. `
    headingText.push(headingTopicText)
  }

  // Catergory, author and date
  for (const [key, value] of Object.entries(allTertiaryFilters)) {
    if (key !== 'date' && value !== '') {
      if (key !== 'author') {
        filtersText = `${key.charAt(0).toUpperCase() + key.slice(1)} - ${value}. `
        headingText.push(filtersText)
      } else if (key === 'author' && value.length > 0) {
        const authorList: string[] = []
        value.map((author: string) => {
          authorList.push(`${author}`)
        })
        headingText.push(
          `${key.charAt(0).toUpperCase() + key.slice(1)} - ${authorList.join(', ')}. `
        )
      }
    } else if (key === 'date' && value !== null) {
      const filterDate: any = value
      if (filterDate.year !== '') {
        headingText.push(
          'Date - ',
          filterDate.month,
          filterDate.month !== '' ? ' ' : '',
          filterDate.year,
          '. '
        )
      }
    }
  }

  const headingCopy = () => {
    if (headingText.length === 0) {
      return 'Article results for all articles.'
    } else {
      const formattedHeadingText = `Article results for: ${headingText.join('')}`
      return formattedHeadingText
    }
  }

  return headingCopy()
}

type buildHeadingTextProps = {
  primaryFilters: {
    searchTerm: {
      value: string
    }
  }
  secondaryFilters: {
    contentTypes: string[]
    topics: string[]
  }
  tertiaryFilters: {
    date: {
      month: string
      year: string
    }
    author: any
    category: string
  }
}
