import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'

import styles from './PISearchBodyContent.module.scss'

export const PISearchBodyContent: FunctionComponent<Props> = ({ children }) => {
  return (
    <section className={styles.searchBody} data-testid="ea-search-body-content">
      {children}
    </section>
  )
}

type Props = {
  children?: ReactNode
}
