import type { FunctionComponent } from 'react'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CustomDropdown, TypographyV2 as Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import { UpdateUrl } from '../../../../pages/search-results/utils/UpdateUrl'
import styles from './PISortMenu.module.scss'

export const PISortMenu: FunctionComponent<Props> = ({
  className,
  items,
  defaultOption,
  ...rest
}) => {
  const history = useHistory()
  const { pathname, search } = useLocation()

  const optionHandler = (ev) => {
    if (ev.value.trim().length !== 0) {
      const buildUrlProps = {
        keys: ['filters.sortBy'],
        values: [ev.value.trim()],
        pathname: pathname,
        search: search,
      }

      const itemText = ev.value.trim() === 'desc' ? 'Oldest first' : 'Newest first'

      dynamicGa4DataLayerPush({
        event: 'click_dropdown',
        item_group: 'refine results',
        action_group: 'sort by',
        item_text: itemText,
      })

      window.dataLayer.push({})

      dynamicGa4DataLayerPush({
        wcdPageUrl: window.location.toString(),
        vertical: 'policy-and-insight',
        content_type: 'search',
        paidAccess: 'free',
      })

      history.push(UpdateUrl(buildUrlProps))
    }
  }

  return (
    <div
      className={classnames(className, styles.sortMenuInSortby)}
      {...rest}
      data-testid="ea-sort-menu"
    >
      <div className={styles.sortMenuAlign}>
        <Typography tag="span" textStyle="sb-text-body-default-regular">
          Sort by:
        </Typography>

        <div className={styles.customWrapper}>
          <CustomDropdown
            className={styles.dropdownContainer}
            classNameSelectedContainer={styles.selectedContainer}
            classNameOptionsContainer={styles.optionsContainer}
            options={items}
            defaultOption={defaultOption}
            handleSortByCallback={optionHandler}
            data-testid="ea-sort-menu-item"
            aria-controls="ea-search-results"
          />
        </div>
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className?: string
  items: { label: string; value: string }[]
  defaultOption?: number
}
