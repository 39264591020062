import type { FunctionComponent } from 'react'
import React, { Fragment, useEffect, useState } from 'react'
import { CheckButton, LinkButton, TypographyV2 as Typography } from '@which/seatbelt'
import { ChevronDownIcon, ChevronUpIcon } from '@which/seatbelt/src/components/Icons/Navigational'
import { dynamicGa4DataLayerPush } from '@which/shared'

import styles from './PICheckButtonFilter.module.scss'

export const PICheckButtonFilter: FunctionComponent<Props> = ({
  data,
  filterType,
  showMore,
  parentText,
}) => {
  const [isShowingMore, setIsShowingMore] = useState(false)
  useEffect(() => {
    setIsShowingMore(
      sessionStorage.getItem(`isShowingMore${filterType}`)
        ? sessionStorage.getItem(`isShowingMore${filterType}`)?.toLowerCase?.() === 'true'
        : false
    )
  }, [filterType])

  if (!data) {
    return null
  }

  const maxFilters = showMore ? (isShowingMore ? data.length : 6) : data.length

  return (
    <div className={styles.checkButtonFilter} data-testid="ea-check-button-filter">
      <div className={styles.buttonWrapper}>
        {data.slice(0, maxFilters).map((button) => {
          return (
            <Fragment key={button.value}>
              {button.value && (
                <CheckButton
                  data-testid="ea-check-button"
                  id={button.value}
                  key={button.value}
                  name={button.value}
                  onChangeCallback={() =>
                    button.onChangeCallback({ id: button.value, type: filterType, parentText })
                  }
                  primaryLabel={button.label}
                  secondaryLabel={button.count}
                  value={button.value}
                  selected={button.selected}
                  aria-controls="ea-search-results"
                />
              )}
            </Fragment>
          )
        })}
        {showMore && (
          <div className={styles.checkButtonFilterShowMoreFilters}>
            <LinkButton
              onClick={() => {
                setIsShowingMore(!isShowingMore)
                sessionStorage.setItem(`isShowingMore${filterType}`, (!isShowingMore).toString())
                dynamicGa4DataLayerPush({
                  event: 'click_link_showmore',
                  item_group: 'refine results',
                  item_parent_text: parentText,
                  item_text: !isShowingMore ? 'Show more' : 'Show less',
                })
              }}
              data-testid="show-more-filters"
              icon={isShowingMore ? ChevronUpIcon : ChevronDownIcon}
            >
              <Typography tag="span" textStyle="sb-text-interface-body-small-regular">
                {isShowingMore ? 'Show less' : 'Show more'}
              </Typography>
            </LinkButton>
          </div>
        )}
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  data?: {
    label: string
    count: string
    value: string
    selected: boolean
    onChangeCallback: any
  }[]
  filterType?: string
  showMore?: boolean
  parentText?: string
}
