import type { FunctionComponent } from 'react'
import React from 'react'

import { usePolicyAndInsightSearchResultsQuery } from '../../generated/frontend'
import { Loader } from '../../shared/components/Loader'
import { getDataLayerScripts } from '../../shared/data-layer'
import { useBaseMetaTags } from '../../shared/hooks/useBaseMetaTags'
import { useFullUrl } from '../../shared/hooks/useFullUrl'
import { PageTemplate } from '../../shared/templates/PageTemplate'
import type { PageInfo } from '../../shared/types/PageInfo'
import { removeFalsy } from '../../shared/utils/remove-falsy'
import { getPolicyAndInsightSearchDataLayer } from './data-layer'
import { useSearchResultsComponents } from './hooks/useSearchResultsComponents'

const SearchResultsPage: FunctionComponent = () => {
  const { data, loading } = usePolicyAndInsightSearchResultsQuery({
    variables: {
      taxonomySlug: '',
      tagSlug: '',
      pageNumber: 1,
      pageSize: 100,
    },
  })

  const components = useSearchResultsComponents(data)
  const pageTitle = 'Search'
  const meta = { title: pageTitle }
  const { getBaseMetaTags } = useBaseMetaTags()
  const { getFullUrl } = useFullUrl()

  const pageInfo: PageInfo = {
    paidAccess: 'free',
    wcdPageUrl: getFullUrl(),
    vertical: 'policy-and-insight',
    content_type: 'search',
  }

  const dataLayer = []
  const mappedPageInfo = removeFalsy(getPolicyAndInsightSearchDataLayer(pageInfo))

  const metaTags = [
    ...getDataLayerScripts([...dataLayer, mappedPageInfo]),
    ...getBaseMetaTags({ meta, noIndex: true }),
  ]

  if (loading) {
    return <Loader />
  }

  return <PageTemplate metaTags={metaTags} components={components} />
}

export default SearchResultsPage
