import type { FunctionComponent } from 'react'
import React from 'react'
import { Pagination } from '@which/seatbelt'

import classNames from 'classnames'

import styles from './PIPagination.module.scss'

export const PIPagination: FunctionComponent<Props> = ({
  callback,
  className,
  currentPage,
  totalPages,
}) => (
  <Pagination
    callback={callback}
    className={classNames(className, styles.piPagination)}
    currentPage={currentPage}
    totalPages={totalPages}
    data-testid="ea-pagination"
    aria-controls="ea-search-results"
  />
)

///////// IMPLEMENTATION /////////

type Props = {
  className?: string
  totalPages?: number
  currentPage?: number
  callback?: (pageNumber: number) => unknown
}
