import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Accordion, AccordionItem, TagClickable, TypographyV2 as Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import type { PolicyAndInsightSearchResultsQuery } from '../../../generated/frontend'
import { Breadcrumb } from '../../../shared/components/Breadcrumb'
import { PIAppliedFilters } from '../../../shared/components/PolicyAndInsight/PIAppliedFilters'
import { PIApplyCancelPanel } from '../../../shared/components/PolicyAndInsight/PIApplyCancelPanel/PIApplyCancelPanel'
import { PICheckboxFilter } from '../../../shared/components/PolicyAndInsight/PICheckboxFilter'
import { PICheckButtonFilter } from '../../../shared/components/PolicyAndInsight/PICheckButtonFilter'
import { PIDropdownFilter } from '../../../shared/components/PolicyAndInsight/PIDropdownFilter'
import { PIFilterButton } from '../../../shared/components/PolicyAndInsight/PIFilterButton'
import { PIPagination } from '../../../shared/components/PolicyAndInsight/PIPagination'
import { PIResultCount } from '../../../shared/components/PolicyAndInsight/PIResultCount'
import { PISearchBodyContent } from '../../../shared/components/PolicyAndInsight/PISearchBodyContent'
import { PISearchContent } from '../../../shared/components/PolicyAndInsight/PISearchContent'
import { PISearchListHeader } from '../../../shared/components/PolicyAndInsight/PISearchListHeader'
import { PISearchResults } from '../../../shared/components/PolicyAndInsight/PISearchResults'
import { PISearchSidebar } from '../../../shared/components/PolicyAndInsight/PISearchSidebar'
import { PISortMenu } from '../../../shared/components/PolicyAndInsight/PISortMenu'
import { isLocal, isProd } from '../../../shared/utils/env-checks'
import styles from '../SearchResultsPage.module.scss'
import { BuildHeadingText } from '../utils/BuildHeadingText'
import { BuildTopics } from '../utils/BuildTopics'
import { CheckboxFilterData } from '../utils/CheckboxFilterData_v1'
import { CreateRemoveableFilters } from '../utils/CreateRemovableFilters'
import { FilterArticleResults } from '../utils/FilterArticleResults'
import { filterArticleData as filterAllArticleData } from '../utils/filters/filterArticleData'
import { getArticleAuthors } from '../utils/getArticleAuthors'
import { getArticleCategories } from '../utils/getArticleCategories'
import { SortArticlesResults } from '../utils/SortArticles'
import { UpdateFilterState } from '../utils/UpdateFilterState'
import { UpdateUrl } from '../utils/UpdateUrl'
import { usePrevious } from '../utils/usePrevious'

export const useSearchResultsComponents = (
  data: PolicyAndInsightSearchResultsQuery | undefined
) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const allArticles = data?.policyAndInsightArticles?.articles
  const [authorArticlesDataset, setAuthorArticlesDataset]: any = useState(allArticles)
  const allArticleAuthors = getArticleAuthors(authorArticlesDataset)
  const [defaultSortOption, setDefaultSortOption] = useState(0)
  const [defaultCategoryOption, setDefaultCategoryOption] = useState(0)
  const [defaultAuthorOption, setDefaultAuthorOption] = useState(0)
  const [searchQueryOnlyArticles, setSearchQueryOnlyArticles]: any = useState(allArticles)
  const [yearFilteredArticles, setYearFilteredArticles]: any = useState(allArticles)
  const [filtersState, setFiltersState]: any = useState({
    primaryFilters: { searchTerm: { value: '' } },
    secondaryFilters: {
      contentTypes: [],
      topics: [],
    },
    tertiaryFilters: {
      year: [],
      author: [],
      category: '',
      page: 1,
    },
    dataSet: {},
  })

  const formatFilters = (filters: typeof filtersState) => {
    const newFilters: any = []

    newFilters.push({
      key: 'filters.searchTerm',
      value: filters.primaryFilters.searchTerm.value,
    })

    Object.entries(filters.secondaryFilters).forEach(([key, val]) => {
      if (val) {
        let newValue = val

        if (Array.isArray(val)) {
          newValue = val.join(',')
        }
        newFilters.push({
          key: `filters.${key}`,
          value: newValue,
        })
      }
    })

    Object.entries(filters.tertiaryFilters).forEach(([key, val]) => {
      if (val) {
        let newValue = val

        if (Array.isArray(val)) {
          newValue = val.join(',')
        }
        newFilters.push({
          key: `filters.${key}`,
          value: newValue,
        })
      }
    })

    return newFilters
  }

  const [filteredDataSet, setFilteredDataSet]: any = useState(
    filterAllArticleData({
      filters: formatFilters(filtersState),
      allArticleData: allArticles,
    })
  )
  const currentPage = filtersState.tertiaryFilters.page
  const filteredArticles = filteredDataSet
  const totalArticlesAmount = filteredArticles?.length || 0
  const articlesPerPage = !isProd() || isLocal() ? 6 : 15
  const totalPages = Math.ceil(totalArticlesAmount / articlesPerPage)
  const firstArticleIndexPerPage = articlesPerPage * currentPage - articlesPerPage
  const lastArticleIndexPerPage = articlesPerPage * currentPage

  const articlesToRender = filteredArticles?.slice(
    firstArticleIndexPerPage,
    lastArticleIndexPerPage
  )

  const pageRangeStart = firstArticleIndexPerPage + 1
  const pageRangeEnd = lastArticleIndexPerPage - (articlesPerPage - (articlesToRender?.length || 0))
  const previousFilterState = usePrevious(filtersState)
  const previousSearch = usePrevious(search)
  const pageFilter = 'filters.page'

  const clearDatalayer = () => {
    window.dataLayer.push({})

    dynamicGa4DataLayerPush({
      wcdPageUrl: window.location.toString(),
      vertical: 'policy-and-insight',
      content_type: 'search',
      paidAccess: 'free',
    })
  }

  const queryParmas = useCallback(() => {
    const queryParams: any = new URLSearchParams(search)

    type queryStringParams = {
      key: string
      value: any
    }

    const queryStrArray: queryStringParams[] = []
    for (const [key, value] of queryParams) {
      const dupKey = queryStrArray.some((queryStr: { key: string }, index: number) => {
        const isDup = queryStr.key === key
        if (isDup) {
          if (Array.isArray(queryStrArray[index].value)) {
            queryStrArray[index].value.push(value)
          } else {
            queryStrArray[index].value = [queryStrArray[index].value, value]
          }
        }
        return isDup
      })

      if (!dupKey) {
        queryStrArray.push({ key, value })
      }
    }
    return queryStrArray
  }, [search])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('policyAndInsightPage', 'search')
      sessionStorage.setItem('pageUrl', `${pathname}${search}`)
    }

    const filterArticleData = allArticles?.filter(function (el: any) {
      const searchTerm = filtersState.primaryFilters.searchTerm.value.toLocaleLowerCase()
      return (
        el.title.toLowerCase().includes(searchTerm) ||
        el.imageAlt.toLowerCase().includes(searchTerm) ||
        el.standFirst.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    })

    const queryStrArray = queryParmas()

    const authorFilteredData = FilterArticleResults({
      articleData: filterArticleData,
      queryStrArray: queryStrArray.filter((obj) => {
        return obj.key !== 'filters.contentTypes'
      }),
      setFilters: setFiltersState,
      filtersState: filtersState,
    })

    const yearFilteredData = FilterArticleResults({
      articleData: filterArticleData,
      queryStrArray: queryStrArray.filter((obj) => {
        return obj.key !== 'filters.year'
      }),
      setFilters: setFiltersState,
      filtersState: filtersState,
    })

    setSearchQueryOnlyArticles(authorFilteredData)
    setYearFilteredArticles(yearFilteredData)
  }, [
    allArticles,
    filtersState,
    filtersState.primaryFilters.searchTerm.value,
    pathname,
    queryParmas,
    search,
  ])

  useEffect(() => {
    if (filtersState === previousFilterState || search !== previousSearch) {
      const queryStrArray = queryParmas()

      const authorFilteredData = FilterArticleResults({
        articleData: allArticles,
        queryStrArray: queryStrArray.filter((obj) => {
          return obj.key !== 'filters.author'
        }),
        setFilters: setFiltersState,
        filtersState: filtersState,
      })

      const filteredData = FilterArticleResults({
        articleData: authorFilteredData,
        queryStrArray: queryStrArray,
        setFilters: setFiltersState,
        filtersState: filtersState,
      })

      if (queryStrArray.filter((e) => e.key === 'filters.sortBy').length > 0) {
        const sortByVal = queryStrArray.filter((e) => e.key === 'filters.sortBy')[0].value

        const sortedArray = SortArticlesResults(filteredData, sortByVal)
        const sortedForAuthorsArray = SortArticlesResults(authorFilteredData, sortByVal)
        setFilteredDataSet(sortedArray.data)
        setAuthorArticlesDataset(sortedForAuthorsArray.data)
        setDefaultSortOption(sortedArray.sortOptionSelected)
      } else {
        //Default articles to sorted by newest first
        const sortedArray = SortArticlesResults(filteredData, 'asc')
        const sortedForAuthorsArray = SortArticlesResults(authorFilteredData, 'asc')

        setFilteredDataSet(sortedArray.data)
        setAuthorArticlesDataset(sortedForAuthorsArray.data)
      }

      const pageObj = queryStrArray.find((param) => param.key === pageFilter)

      if (pageObj && Number(pageObj.value) && Number(pageObj.value) !== currentPage) {
        const updateStateProps = {
          filterState: filtersState,
          type: 'tertiaryFilters',
          key: 'page',
          value: pageObj.value,
        }
        setFiltersState(UpdateFilterState(updateStateProps))
      }
    }
  }, [
    allArticles,
    currentPage,
    filtersState,
    previousFilterState,
    previousSearch,
    queryParmas,
    search,
  ])

  useEffect(() => {
    setDefaultAuthorOption(
      getArticleAuthors(filteredArticles).findIndex(
        (author) => author.value === filtersState.tertiaryFilters.author
      )
    )
    setDefaultCategoryOption(
      getArticleCategories(filteredArticles).findIndex(
        (category) => category.value === filtersState.tertiaryFilters.category
      )
    )
  }, [filteredArticles, filtersState.tertiaryFilters.author, filtersState.tertiaryFilters.category])

  const paginationClickHandler = (page: React.SetStateAction<number>) => {
    const event = {
      value: page,
    }

    clearDatalayer()

    handleValueChange({ event, key: 'page', filterType: 'tertiaryFilters' })
  }

  const [showFilters, setShowFilters] = useState<boolean>(false)

  const displayMobileFilters = () => {
    setShowFilters(!showFilters)
  }

  const handleChange = ({
    id,
    type,
    parentText,
    removedFilter = false,
  }: {
    id: string
    type: string
    parentText?: string
    removedFilter?: boolean
  }) => {
    const getFilter = (filterName: string) => {
      return filterName === 'year'
        ? filtersState.tertiaryFilters[type]
        : filtersState.secondaryFilters[type]
    }

    const correctId = id.replace('-topicselector', '').replace('year-', '')
    const filter = getFilter(type)
    let isRemoved = false
    const filterLowerCase = filter.map((text: string) => text.toLowerCase())
    if (filter && filterLowerCase.includes(correctId.toLowerCase().replace(/\s+/g, '-'))) {
      filter.splice(filterLowerCase.indexOf(correctId.toLowerCase()), 1)
      isRemoved = true
    } else {
      filter.push(correctId.replace(/\s+/g, '-').toLowerCase())
    }

    const buildUrlProps = {
      keys: [`filters.${type}`, pageFilter],
      pathname: pathname,
      search: search,
      values: [filter.toString(), '1'],
    }
    const updateStateProps = {
      filterState: filtersState,
      type: type,
      key: id,
      value: id,
    }
    setFiltersState(UpdateFilterState(updateStateProps))
    dynamicGa4DataLayerPush({
      event: 'click_filter',
      item_group: 'refine results',
      item_text: correctId,
      item_parent_text: parentText,
      action_group: !removedFilter ? `filter ${!isRemoved}` : 'clear filters',
    })

    history.push(UpdateUrl(buildUrlProps), { updateQueryString: true })

    clearDatalayer()
  }

  const handleAuthorValueChange = ({
    event,
    key,
    filterType,
    parentText,
  }: {
    event
    key: string
    filterType: string
    parentText?: string
  }) => {
    const { value } = event
    const filter = filtersState.tertiaryFilters['author']
    let isRemoved = false
    if (!value) {
      filter.splice(0, filter.length)
    } else {
      if (filter && filter.includes(value)) {
        filter.splice(filter.indexOf(value), 1)
        isRemoved = true
      } else {
        filter.push(value)
      }
    }

    const buildUrlProps = {
      keys: [`filters.${key}`, pageFilter],
      pathname: pathname,
      search: search,
      values: [filter.toString(), '1'],
    }

    const updateStateProps = {
      filterState: filtersState,
      type: filterType,
      key: key,
      value: filter,
    }

    setFiltersState(UpdateFilterState(updateStateProps))

    if (parentText) {
      dynamicGa4DataLayerPush({
        event: 'click_dropdown_filter',
        item_text: event.label,
        item_parent_text: key,
        item_group: 'refine results',
        item_grandparent_text: parentText,
        action_group: `filter ${!isRemoved}`,
      })
    }

    history.push(UpdateUrl(buildUrlProps), { updateQueryString: true })
    clearDatalayer()
  }

  const handleValueChange = ({
    event,
    key,
    filterType,
    parentText,
  }: {
    event
    key: string
    filterType: string
    parentText?: string
  }) => {
    const { value } = event
    const isPagination = key === 'page'

    const buildUrlProps = {
      keys: isPagination ? [`filters.${key}`] : [`filters.${key}`, pageFilter],
      pathname: pathname,
      search: search,
      values: isPagination ? [value] : [value, '1'],
    }

    const updateStateProps = {
      filterState: filtersState,
      type: filterType,
      key: key,
      value: value,
    }

    setFiltersState(UpdateFilterState(updateStateProps))

    if (parentText) {
      dynamicGa4DataLayerPush({
        event: 'click_dropdown_filter',
        item_text: event.label,
        item_parent_text: key,
        item_group: 'refine results',
        item_grandparent_text: parentText,
        action_group: 'filter true',
      })
    }

    history.push(UpdateUrl(buildUrlProps), { updateQueryString: !isPagination })
    clearDatalayer()
  }

  const checkboxData = CheckboxFilterData(
    searchQueryOnlyArticles,
    filtersState.secondaryFilters.contentTypes,
    'contentTypes'
  )

  const articleYears = CheckboxFilterData(
    yearFilteredArticles,
    filtersState.tertiaryFilters.year,
    'year'
  )

  articleYears.sort((a, b) => Number(b.label) - Number(a.label))

  const articleCategories = getArticleCategories(filteredArticles)

  const filteredAuthors = allArticleAuthors.filter(
    (el) => !filtersState.tertiaryFilters.author.includes(el.value)
  )

  const clearAllFiltersHandler = () => {
    setFiltersState({
      primaryFilters: { searchTerm: { value: '' } },
      secondaryFilters: {
        contentTypes: [],
        topics: [],
      },
      tertiaryFilters: {
        year: [],
        author: [],
        category: '',
        page: 1,
      },
      dataSet: {},
    })
    history.push('/policy-and-insight/search')
    dynamicGa4DataLayerPush({
      event: 'click_filter',
      item_group: 'refine results',
      item_text: 'Clear all filters',
      item_parent_text: undefined,
      action_group: 'clear filters',
    })
  }

  const removeFilterClicked = (filter: any) => {
    const { text, id, linkedFilters, type } = filter
    if (id === 'contentTypes' || id === 'topics') {
      const formattedText = text.replace(/\s/g, '-').toLowerCase()
      handleChange({ id: formattedText, type: id, parentText: id, removedFilter: true })
    } else if (id === 'author') {
      handleAuthorValueChange({ event: { value: text }, key: id, filterType: id })
    } else if (id === 'year') {
      const formattedText = text.replace('Year - ', '')
      handleChange({ id: formattedText, type: id, parentText: id, removedFilter: true })
    } else {
      const buildUrlKeys: string[] = []
      const buildUrlValues: string[] = []

      buildUrlKeys.push(`filters.${id}`)
      buildUrlValues.push('')
      buildUrlKeys.push(pageFilter)
      buildUrlValues.push('1')

      linkedFilters?.map((linkedFilter) => {
        buildUrlKeys.push(`filters.${linkedFilter.name}`)
        buildUrlValues.push(linkedFilter.value)
      })

      const buildUrlProps = {
        keys: buildUrlKeys,
        pathname: pathname,
        search: search,
        values: buildUrlValues,
      }

      const updateStateProps = {
        filterState: filtersState,
        type: type,
        key: id,
        value: '',
      }
      setFiltersState(UpdateFilterState(updateStateProps))
      dynamicGa4DataLayerPush({
        event: 'click_filter',
        item_group: 'refine results',
        item_text: text,
        item_parent_text: type,
        action_group: 'clear filters',
      })
      history.push(UpdateUrl(buildUrlProps), { updateQueryString: true })
      clearDatalayer()
    }
  }

  const topics = BuildTopics(filteredArticles, filtersState, handleChange)

  const removableFilters = CreateRemoveableFilters(filtersState)

  const getDefaultDropdown = (id: string) => {
    switch (id) {
      case 'category':
        return defaultCategoryOption
      case 'author':
        return defaultAuthorOption
      default:
        return 0
    }
  }

  const handleAccordionToggle = (isOpen: boolean, label: string) => {
    const action = isOpen ? 'Expand' : 'Collapse'
    dynamicGa4DataLayerPush({
      event: 'click_accordion',
      action_group: action,
      item_group: 'refine results',
      item_text: label,
    })
  }

  return {
    PIHeader: () => (
      <>
        <Breadcrumb
          heading={{ text: 'Policy and insight', href: '/policy-and-insight' }}
          currentPage={null}
          links={[]}
        />
        <Typography
          className={styles.srOnly}
          textStyle="sb-text-heading-page-title"
          data-testid="headline"
          tag="h1"
        >
          {BuildHeadingText(filtersState)}
        </Typography>
      </>
    ),
    PISearchContent: ({ ...props }) => {
      const { searchBarPlaceholder, searchBarButtonText } = props

      return (
        <>
          <PISearchContent>
            <PISearchSidebar
              cssClassName={showFilters ? 'ShowFilters' : ''}
              sectionHeader="Filters"
            >
              <Accordion data-testid="ea-dropdown-filter-accordion">
                {checkboxData.length > 0 && (
                  <AccordionItem
                    callback={(isOpen: boolean) => {
                      handleAccordionToggle(isOpen, 'Content type')
                    }}
                    content={
                      <PICheckboxFilter
                        data={checkboxData}
                        filterType="contentTypes"
                        onChangeCallback={handleChange}
                        parentText="Content type"
                      />
                    }
                    key="Content type"
                    label="Content type"
                    renderOpen
                  />
                )}

                {articleYears.length > 0 && (
                  <AccordionItem
                    callback={(isOpen: boolean) => {
                      handleAccordionToggle(isOpen, 'Year')
                    }}
                    content={
                      <PICheckboxFilter
                        data={articleYears}
                        filterType="year"
                        onChangeCallback={handleChange}
                        parentText="Year"
                      />
                    }
                    key="Date Filter"
                    label="Year"
                    renderOpen
                  />
                )}

                {articleCategories.length > 0 && (
                  <AccordionItem
                    callback={(isOpen: boolean) => {
                      handleAccordionToggle(isOpen, 'Subject area')
                    }}
                    content={
                      <>
                        <PIDropdownFilter
                          data={articleCategories}
                          defaultOption={getDefaultDropdown('category')}
                          onChangeCallback={handleValueChange}
                          parentText="Subject area"
                          id="category"
                          filterType="tertiaryFilters"
                        />
                      </>
                    }
                    key="Category filter"
                    label="Subject area"
                    renderOpen
                  />
                )}

                {topics.length > 0 && (
                  <AccordionItem
                    callback={(isOpen: boolean) => {
                      handleAccordionToggle(isOpen, 'Topics')
                    }}
                    key="Topics"
                    content={
                      <PICheckButtonFilter
                        data={topics}
                        filterType="topics"
                        showMore
                        parentText="Topics"
                      />
                    }
                    label="Topics"
                    renderOpen
                  />
                )}

                {allArticleAuthors.length > 0 && (
                  <>
                    <AccordionItem
                      className={styles.authors}
                      callback={(isOpen: boolean) => {
                        handleAccordionToggle(isOpen, 'Author')
                      }}
                      content={
                        <>
                          <PIDropdownFilter
                            data={filteredAuthors}
                            defaultOption={getDefaultDropdown('author')}
                            onChangeCallback={handleAuthorValueChange}
                            id="author"
                            filterType="tertiaryFilters"
                            className={styles.authorDropdown}
                            parentText="Author"
                          />
                          {filtersState.tertiaryFilters.author?.map((author) => (
                            <div key={author} className={styles.tagWrapper}>
                              <TagClickable
                                clickHandler={() =>
                                  removeFilterClicked({ text: author, id: 'author' })
                                }
                                text={author}
                                withIcon
                                id={`author-${author}`}
                              />
                            </div>
                          ))}
                        </>
                      }
                      key="Author filter"
                      label="Author"
                      renderOpen
                    />
                  </>
                )}
              </Accordion>

              <PIApplyCancelPanel
                resultsCount={totalArticlesAmount}
                onClick={displayMobileFilters}
              />
            </PISearchSidebar>
            <PISearchBodyContent>
              <PISearchListHeader
                searchBar={{
                  buttonText: searchBarButtonText,
                  placeholderText: searchBarPlaceholder,
                }}
              >
                <PISortMenu
                  items={[
                    { label: 'Newest first', value: 'asc' },
                    { label: 'Oldest first', value: 'desc' },
                  ]}
                  defaultOption={defaultSortOption}
                />
                <PIResultCount
                  className={styles.resultsInHeader}
                  searchTerm={filtersState.primaryFilters.searchTerm.value}
                  end={pageRangeEnd}
                  start={pageRangeStart}
                  total={totalArticlesAmount}
                />
                <PIFilterButton
                  filterCount={removableFilters.length}
                  onClick={displayMobileFilters}
                />
              </PISearchListHeader>

              <PIAppliedFilters
                filters={removableFilters}
                handleFilterClick={removeFilterClicked}
                handleClearAllFilters={clearAllFiltersHandler}
              />
              <PIResultCount
                searchTerm={filtersState.primaryFilters.searchTerm.value}
                end={pageRangeEnd}
                start={pageRangeStart}
                total={totalArticlesAmount}
              />
              <PISearchResults results={articlesToRender} />
              <PIPagination
                callback={paginationClickHandler}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </PISearchBodyContent>
          </PISearchContent>
        </>
      )
    },
  }
}
