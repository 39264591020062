import { applyFilters } from './applyFilters'
import { getSearchFilteredData } from './getSearchFilteredData'
import { getSearchTerm } from './getSearchTerm'

export const filterArticleData = ({ allArticleData, filters }: FilterArticleDataProps) => {
  if (filters.length === 0) {
    return allArticleData
  }

  const searchFilter = filters.filter(getSearchTerm)
  const searchValue = searchFilter.length > 0 ? searchFilter[0].value : ''

  let primaryArticleData = allArticleData

  if (searchValue !== '') {
    primaryArticleData = getSearchFilteredData({
      allArticleData: allArticleData,
      searchValue: searchValue,
    })
  }

  const filteredArticleData = applyFilters(primaryArticleData, filters)

  return filteredArticleData
}

type FilterArticleDataProps = {
  allArticleData: any
  filters: { key: string; value: string }[]
}
