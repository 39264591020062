const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const CreateRemoveableFilters = (filtersState: {
  primaryFilters: { searchTerm: { value: string } }
  secondaryFilters: {
    contentTypes: string[]
    topics: string[]
  }
  tertiaryFilters: {
    year: string[]
    author: string[]
    category: string
  }
}) => {
  type AppliedFilters = {
    id: string
    linkedFilters?: { name: string; value: string }[]
    text: string
    type: string
  }

  const filters: AppliedFilters[] = []

  Object.entries(filtersState).forEach(([key]) => {
    switch (key) {
      case 'primaryFilters':
        if (filtersState.primaryFilters.searchTerm.value) {
          filters.push({
            id: 'searchTerm',
            text: filtersState.primaryFilters.searchTerm.value,
            type: 'primaryFilters',
          })
        }
        break
      case 'secondaryFilters':
        if (
          filtersState.secondaryFilters.contentTypes &&
          filtersState.secondaryFilters.contentTypes.length > 0
        ) {
          filtersState.secondaryFilters.contentTypes.forEach((articleType) => {
            const filterName = articleType.replace(/-/g, ' ')
            filters.push({
              id: 'contentTypes',
              text: capitalize(filterName),
              type: 'secondaryFilters',
            })
          })
        }

        if (
          filtersState.secondaryFilters.topics &&
          filtersState.secondaryFilters.topics.length > 0
        ) {
          filtersState.secondaryFilters.topics.forEach((topic: string) => {
            const filterName = topic.replace(/-/g, ' ')
            filters.push({ id: 'topics', text: capitalize(filterName), type: 'secondaryFilters' })
          })
        }
        break
      case 'tertiaryFilters':
        if (filtersState.tertiaryFilters.author && filtersState.tertiaryFilters.author.length > 0) {
          filtersState.tertiaryFilters.author.forEach((author: string) => {
            filters.push({ id: 'author', text: author, type: 'tertiaryFilters' })
          })
        }

        if (filtersState.tertiaryFilters.category) {
          filters.push({
            id: 'category',
            text: capitalize(filtersState.tertiaryFilters.category),
            type: 'tertiaryFilters',
          })
        }

        if (filtersState.tertiaryFilters.year?.length) {
          filtersState.tertiaryFilters.year.forEach((year: string) => {
            filters.push({
              id: 'year',
              text: `Year - ${year}`,
              type: 'tertiaryFilters',
            })
          })
        }

        break
      default:
        return filters
    }
  })

  return filters
}
