export const removeDuplicateArticles = (articleData) => {
  if (articleData) {
    const sanitisedArticles = articleData.filter(
      (v, i, a) => a.findIndex((v2) => ['title'].every((k) => v2[k] === v[k])) === i
    )

    return sanitisedArticles // Returns the filtered articles
  }
  return articleData
}
