import type { FunctionComponent, ReactNode } from 'react'
import React from 'react'
import { Heading } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './PISearchSidebar.module.scss'

export const PISearchSidebar: FunctionComponent<Props> = ({
  children,
  sectionHeader,
  cssClassName,
}) => {
  return (
    <aside
      data-testid="ea-search-sidebar"
      className={classnames(styles.section, styles[`section-${cssClassName}`])}
    >
      <div className={styles.sectionHeader}>
        <Heading headingType="small" heading={sectionHeader} headingTag="h1" />
      </div>

      <div
        data-testid="ea-section-content"
        className={classnames(styles.sectionContent, styles[`section-${cssClassName}Content`])}
      >
        {children}
      </div>
    </aside>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  children?: ReactNode
  sectionHeader: string
  cssClassName?: string
}
