import type { PolicyAndInsightSearchResultsQuery } from '../../../generated/frontend'

type Author = {
  label: string
  value: string
}

export const getArticleAuthors = (
  articles: PolicyAndInsightSearchResultsQuery['policyAndInsightArticles']['articles']
) => {
  const articleAuthorsData: Author[] = []

  articles?.map((article) => {
    for (const author of article.authors) {
      const authorExists = articleAuthorsData.find((x) => x.label === author)

      if (!authorExists) {
        articleAuthorsData.push({ label: author, value: author })
      }
    }
  })

  articleAuthorsData.sort((a, b) => a.label.localeCompare(b.label))
  articleAuthorsData.unshift({ label: 'All authors', value: '' })

  return articleAuthorsData
}
