import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem } from '@which/seatbelt'

import { PISearchBox } from '../PISearchBox'
import styles from './PIHeaderSearch.module.scss'

export const PIHeaderSearch: FunctionComponent<Props> = ({ searchBar }) => {
  return (
    <Grid className={styles.headerSearchWrapper} data-testid="ea-header-search">
      <GridItem span={{ large: 12 }} columnStart={{ large: 1 }} className={styles.content}>
        <PISearchBox {...searchBar} />
      </GridItem>
    </Grid>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  searchBar: { placeholderText: string; buttonText: string }
}
