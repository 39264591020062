import type { FunctionComponent } from 'react'
import React from 'react'
import { CustomDropdown, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './PIDropdownFilter.module.scss'

export const PIDropdownFilter: FunctionComponent<Props> = ({
  className,
  data,
  defaultOption,
  disabled = false,
  id,
  title,
  filterType,
  parentText,
  onChangeCallback,
  ...rest
}) => {
  const renderDropdown = (dropdown) => (
    <div className={styles.dropdownFilterWrapper} key={dropdown.id}>
      {title && (
        <Typography
          tag="p"
          className={styles.dropdownFilterTitle}
          textStyle="sb-text-body-small-regular"
        >
          {title}
        </Typography>
      )}
      <CustomDropdown
        className={styles.dropdownContainer}
        classNameSelectedContainer={styles.selectedContainer}
        classNameOptionsContainer={styles.optionsContainer}
        defaultOption={defaultOption}
        handleSortByCallback={(event) => {
          onChangeCallback({ event, key: id, filterType, parentText })
        }}
        options={data}
        disabled={disabled}
        aria-controls="ea-search-results"
      />
    </div>
  )

  return (
    <div className={classnames(className, styles.dropdownFilter)} {...rest}>
      <div data-testid={`ea-dropdown-filter-${id}`} key={id}>
        {data && renderDropdown(data)}
      </div>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className?: string
  data: {
    label: string
    value: string
  }[]
  defaultOption: number | undefined
  disabled?: boolean
  id: string
  title?: string
  filterType?: string
  onChangeCallback: ({ event, key, filterType, parentText }) => void
  parentText?: string
}
