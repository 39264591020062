import type { FunctionComponent } from 'react'
import React from 'react'

import { PIArticleContentCard } from '../PIArticleContentCard'
import styles from './PISearchResults.module.scss'

export const PISearchResults: FunctionComponent<Props> = ({ results }) => {
  return results?.length ? (
    <main
      aria-live="polite"
      className={styles.piSearchResults}
      data-testid="ea-search-results"
      role="region"
      id="ea-search-results"
    >
      {results?.map(
        (
          { title, topicHeading, standFirst, href, image, imageAlt, date, estimatedReadingTime },
          index
        ) => {
          const d: Date = new Date(date)

          return (
            <PIArticleContentCard
              key={title}
              title={title}
              heading={topicHeading}
              strapline={standFirst}
              href={href}
              image={image}
              imageAlt={imageAlt}
              date={d}
              estimatedReading={estimatedReadingTime}
              orientation="horizontal"
              className={styles.piSearchResultsCard}
              cardIndex={index}
              parentText="Search results"
              imageRight={true}
            />
          )
        }
      )}
    </main>
  ) : (
    <div>None available</div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  results?: {
    title: string
    topicHeading: {
      text: string
      icon: string
      category: string
    }
    standFirst?: string
    href: string
    image?: string
    imageAlt?: string
    date: string
    estimatedReadingTime?: string
  }[]
}
