import fuzzysort from 'fuzzysort'

import type { PolicyAndInsightSearch } from '../../../../generated/frontend'

export const getSearchFilteredData = ({ searchValue, allArticleData }: SearchFilteredProps) => {
  if (allArticleData && allArticleData.length > 0) {
    const fuzzysortResults = fuzzysort.go(searchValue, allArticleData, {
      keys: [
        'title',
        'standFirst',
        'imageAlt',
        'category',
        'authors',
        'href',
        'topicHeading',
        'trendingTopics',
        'image',
        'date',
        'estimatedReadingTime',
      ],
    })

    const searchFilteredData = fuzzysortResults.map((result) => {
      return result.obj
    })

    return searchFilteredData
  }
  return allArticleData
}

type SearchFilteredProps = {
  searchValue: string
  allArticleData: PolicyAndInsightSearch[]
}
